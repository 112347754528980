
.imgSlide{
    width: 430px;
    height: 530px;
    margin: 30px;
    border-radius: 25px;

}

.controlLeft, .controlRight{
    margin: 15px;
}

@media (max-width: 499px) {
    
    .imgSlide{
        width: 230px;
        height: 330px;
        margin: 10px;
    }
}  