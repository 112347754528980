@import url('https://fonts.googleapis.com/css2?family=Caudex&display=swap');

footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    background-color: #212121;
    padding: 28px;
    padding-top: 150px;
    padding-bottom: 150px;
    flex-wrap: wrap;
    color: #e592ac;
    
    
}

footer div {
    height: 254px;
    border-top: 1px solid #e592ac;
    border-bottom: 1px solid #e592ac;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

footer div:nth-child(2) {
    font-family: lato;
}

footer div:first-child {
    max-width: 295px;
    justify-content: normal;
    padding: 30px 20px;
    border-left: 1px solid #e592ac;
    border-right: 1px solid #e592ac;
}

footer div:last-child {
    border-left: 1px solid #e592ac;
    border-right: 1px solid #e592ac;

}

footer a {
    font-size: 16px;
    text-decoration: none;
    color: #e592ac;
}

footer a:first-child {
    text-decoration: none;
    color: #e592ac;
    font-family: caudex, serif;
    font-size: 26px;
    letter-spacing: 0.08em;
}


.descricao p {
    text-align: center;
    margin: 10px 0 10px 0;
    color: #e592ac;
}

.infos p {
    text-align: center;
    margin: 0 0 20px 0;
}

footer div img {
    width: 20px;
    height: 20px;
}

.logo_footer {
    width: 100px;
    height: 92px;
}

@media (max-width: 499px) {

    footer div {
        border: 1px solid #e592ac;
        min-width: 300px;
    }
}