@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');

.container {
    margin-top: 140px;
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container div video {
    margin: 0;
}

.corFundo {
    width: 100vw;
    height: 550px;
    top: -125px;
    z-index: -1;
    position: absolute;
    background-color: #e592ac;
}

.fitstSection {
    display: flex;
}

.about {
    height: 450px;
    width: 580px;
    display: flex;
    position: relative;
    left: 40px;
    bottom: 10px;
    flex-direction: column;
    background-color: #f8f4f4;
    padding: 50px 80px 50px 70px;
}

.about h1 {
    margin-bottom: 20px;
    font-family: Forum, serif;
    font-size: 30px;
    color: #021a4a;
    letter-spacing: 2.4px;
    
    
}

.text {
    font-size: 16px;
}

.diVideo {
    height: 320px;
    position: relative;
    top: 45px;
    right: 30px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;    
    
    
    /* @media (max-width: 1120px) {
        top: 50px;
        justify-content: center;
        align-items: center;
        position: static;
        margin: 40px;
    } */
}

.evideo{
    width:620px;
}


@media (max-width: 499px) {

    .fitstSection {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about {
        height: 450px;
        width: 330px;
        left: 36px;
        bottom: 6px;
        padding: 40px 60px 40px 50px;
        justify-content: center;
        align-items: center;
        position: static;
        
    }

    .evideo {
        width: 330px;   
    }

    .diVideo {
        height: 196px;
        margin: 10px;
        top: 50px;
        justify-content: center;
        align-items: center;
        position: static;
    
    }

    .text {
        font-size: 12px;
        width: 230px;
    }

    .about h1 {
        margin-bottom: 16px;
        font-size: 22px;
        letter-spacing: 2px;
    }
    }