.container {
    margin: 40px 0px 0px 0px;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    
}

.container h1 {
    margin-bottom: 20px;
    font-family: Forum, serif;
    font-size: 40px;
    color: #021a4a;
    letter-spacing: 2.4px;
    
    @media (max-width: 499px) {
        margin-top: 60px;
        margin-bottom: 24px;
        font-size: 30px;
        letter-spacing: 2px;
    }
    
}