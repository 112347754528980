@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 20px 0;
    box-shadow: rgba(229, 146, 172, 0.4) -5px 5px, rgba(229, 146, 172, 0.3) -10px 10px, rgba(229, 146, 172, 0.2) -15px 15px, rgba(229, 146, 172, 0.1) -20px 20px, rgba(229, 146, 172, 0.05) -25px 25px;
}

.conteudo {
    padding: 30px;
    justify-content: center;
    width: 260px;
    background-color: rgb(229, 146, 172);
    height: 380px;
}

.conteudo h4 {
    text-align: center;
    font-family: Forum, serif;
    font-size: 30px;
    margin-bottom: 40px;
}

.conteudo p {
    font-size: 18px;
}

.imagem{
    width: 380px;
    height: 380px;
}

.imagem img {
    height: 380px;
    width: 380px;
}

@media (max-width: 499px){

    .container {
        flex-direction: column;
    }

    .imagem img {
        width: 330px;
        height: 330px;
    }

    .imagem{
        width: 330px;
        height: 330px;
    }

    .conteudo {
        width: 330px;
        height: 260px;
    }

}