.card {
    height: 300px;
    width: 200px;
    margin: 20px;
    box-shadow: 5px 5px 20px black;
    overflow: hidden;
}

.card img {
    height: 300px;
    object-fit: cover;
    border-radius: 3px;
    transition: 0.5s;
}

.card h4 {
    margin: 10px;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.intro {
    height: 70px;
    width: 200px;
    padding: 4px;
    box-sizing: border-box;
    position: relative;
    background: rgb(27, 27, 27, .5);
    bottom: 70px;
    color: white;
    transition: 0.5s;
}

.card p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    margin: 20px;
    visibility: hidden;
    opacity: 0;
}

.card:hover {
    cursor: pointer;
}

.card:hover .intro {
    height: 200px;
    bottom: 200px;
    background: rgba(0, 0, 0, 0.63);
}


.card:hover p {
    opacity: 1;
    visibility: visible;
}

.card:hover img {
    transform: scale(1.1) rotate(-3deg);
}