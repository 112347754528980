.container_header {
    width: 100vw;
    height: 125px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

}

.logo_header {
    padding-left: 17vw;
    /* @media (max-width: 1000px) {
        padding-left: 8vw;
    } */
}

.logo_header img {
    width: 100px;
    /* @media (max-width: 1000px) {
        width: 60px;
    } */

}


.ul_header,
.ul_header li {
    text-decoration: none;
    display: flex;
}

header .a,
header a {
    text-decoration: none;
    font-size: 16px;
    margin: 0 10px;
    padding: 10px 30px;
    background-color: transparent;
    color: black;

    /* @media (max-width: 1000px) {
        padding: 14px 34px;
        font-size: 12px;
    } */
}

header .a:hover,
header .a:focus
{
    transition: 0.8s;
    background-color: #f0ece4;
}


.agendamento_header {
    display: flex;
    width: 30vw;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #212121;
    
    
    /* @media (max-width: 1100px) {
        width: 26vw;
    } */

}

.agendamento_header .a,
.agendamento_header a {
    background-color: #e592ac;
    color: white;
    border: 1px solid #844a5d;
}


.agendamento_header .a:focus,
.agendamento_header a:focus .agendamento_header .a:hover,
.agendamento_header a:hover {
    background-color: black;
}



/* .container_header.scrollHide {
    opacity: 0;
} */

/* Hamburguer */

.icon{
    position: absolute;
    z-index: 4;
    right: 10%;
    width: fit-content;
    height: 44px;
    cursor: pointer;
    transform: scaleX(-1);
}

.hamburguer {
    top: 50%;
    right: 10%;
    width: 20px;
    height: 4px;
    background: #e592ac;
    position: absolute;
    /* box-shadow: 0 2px 5px #e592ac; */
    transition: 0.5s;

}

.hamburguer:before {
    top: -8px;
    content: '';
    position: absolute;
    width: 27px;
    height: 4px;
    background: #e592ac;
    /* box-shadow: 0 2px 5px #e592ac; */
    transition: 0.5s;
}

.hamburguer:after {
    top: 8px;
    content: '';
    position: absolute;
    width: 27px;
    height: 4px;
    background: #e592ac;
    /* box-shadow: 0 2px 5px #e592ac; */
    transition: 0.5s;
}


.list{
    display: flex;
    flex-direction: column;
    right: 10%;
    padding: 45px 0 0;
    flex-wrap: nowrap;
    color: white;
    font-size: 2rem;
    font-weight: 600;

}

.list .listItems {
    padding: 0;
    list-style: none;
    
}

.list .listItems .b{
    font-size: 1.2rem;
    margin: 2px 8px;
    cursor: pointer;
    color: white;

}

.list .listItems .b:hover{
    font-weight: 800;

}

.icon.iconActive .hamburguer {
    background: transparent ;
    box-shadow: 0 2px 5px transparent;
}

.icon.iconActive .hamburguer:after {
    top: 0;
    background: #e592ac;
    transform: rotate(225deg);
    box-shadow: 0 -2px 5px #e592ac;
}

.icon.iconActive .hamburguer:before {
    top: 0;
    background: #e592ac;
    transform: rotate(135deg);
    box-shadow: 0 -2px 5px #e592ac;
}

.menu.menuOpen {
    width: 200px;
    height: 100vh;
    background: #212121;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;
    box-shadow: 10px 0px 69px 0px #e592ac96;
    transition: all 1s;
}

.menu.menuClose {
    width: 0;
    height: 100vh;
    background: #212121;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;
    transition: all 1s;
}

@media (max-width: 499px) {
    
    .container_header {
        height: 70px;
        background-color: #212121;
        z-index: 3;
        position: fixed;
        top: 0;
        /* box-shadow: 0px 0px 5px 0px #e592ac; */
    }

    
    .hamburguer:after {
        width: 24px;
        height: 4px;
    }

    .logo_header {
        padding-left: 2vw;
    }

    .nav_header{
        display: none;
    }

    .agendamento_header {
        display: none;
    }

    .hamburguer:before {
        width: 24px;
        height: 4px;
    }

    .hamburguer:after {
        width: 24px;
        height: 4px;
    }
}

@media (min-width: 500px) {
    .hamburguer {
        display: none;
    }

}

@media (max-width: 499px) {
    .logo_header img {
        width: 40px;
    }
}