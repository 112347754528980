@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');


main {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    box-sizing: border-box;
    
}

.first-section {
    display: flex;
    flex-direction: column;
}

.first-section h1 {
    font-family: Forum, serif;
    font-size: 39px;
    text-align: center;
    letter-spacing: 3.8px;
    margin-bottom: 30px;
}

main video {
    width: 700px;
    height: 450px;
}



.servicos_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.servicos_main h2 {
    font-family: Forum, serif;
    font-size: 37px;
    letter-spacing: 3.8px;
    margin: 36px 0 20px 0;
}

.section-cards_main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

/* Bottom */

.div-button {
    margin: 20px;
}

.glow-on-hover {
    width: 220px;
    height: 50px;
    text-decoration: none;
    font-size: 20px;
    padding: 5px;
    border: none;
    outline: none;
    color: #fff;
    background: #ffe4c4b4;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ffe4c480, #330239, #ffe4c480);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #330239
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #8f8f8f;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.thrid-section {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px 0px 100px 0px;
    
}

.slide {
    width: 460px;
    height: 530px;
}

@media (max-width: 499px) {

        main video {
            width: 340px;
            height: 190px;
        }

        .first-section h1 {
            font-size: 22px;
            margin: 22px 0 20px 0; 
        }

        .servicos_main h2 {
            font-size: 20px;
            margin: 20px 0 10px 0;
        }

        .section-cards_main {
            grid-template-columns: repeat(1, 1fr);
        }

        .slide {
            width: 240px;
            height: 330px;
        }
}

/* .backG {
    background: url(/public/cardPhotos/gifA.gif);
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(15px);
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
} */

